<template>
  <div>
    <div ref="bindMobileModalRef" class="component-bindMobileModal-container"></div>
    <a-modal
      footer=""
      :maskClosable="false"
      v-model:open="bindMobileModal.isOpen"
      :getContainer="() => $refs.bindMobileModalRef"
      :width="$px2rem(500)"
    >
      <div class="modal-inner-wrapper">
        <div class="operator-box">
          <div class="box-title">
            <div class="iconfont icon-dianhua"></div>
            绑 定 手 机 号
          </div>
          <div class="title-desc">
            根据国家法律法规要求, 您在使用
            <span class="brand">&nbsp;学之策&nbsp;</span>
            网站前 需要先
            <span class="highlight">&nbsp;绑定&nbsp;</span>
            手机号
          </div>
          <div class="form-wrapper">
            <a-form :model="formState" @finish="handleFinish" ref="formRef">
              <a-form-item
                :rules="[
                  { required: true, message: '请输入正确的手机号', trigger: 'blur' },
                  {
                    required: true,
                    pattern:
                      /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
                    message: '请输入正确的手机号',
                    trigger: 'blur'
                  }
                ]"
                name="phone"
              >
                <a-input
                  v-model:value="formState.phone"
                  placeholder="请输入您的手机号"
                  class="h-40"
                >
                  <template #prefix>
                    <div class="iconfont icon-shoujidenglu"></div>
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item
                class="validate-code-box"
                :rules="[
                  {
                    required: true,
                    message: '请输入正确的验证码',
                    trigger: 'blur',
                    pattern: /^\d{6}$/
                  }
                ]"
                name="code"
              >
                <a-input-group compact>
                  <a-input
                    v-model:value="formState.code"
                    placeholder="请输入验证码"
                    class="h-40"
                    autoComplete="off"
                  >
                    <template #prefix>
                      <div class="iconfont icon-yanzhengma-"></div>
                    </template>
                  </a-input>
                  <a-button
                    type="primary"
                    :loading="validateCodeInterval.isLoading"
                    :disable="validateCodeInterval.isWait"
                    @click="sendApllicaionMessage"
                    class="width-102px h-40"
                    >{{
                      validateCodeInterval.isWait
                        ? validateCodeInterval.time + ' 秒'
                        : validateCodeInterval.isLoading
                          ? '正在发送'
                          : '发送验证码'
                    }}</a-button
                  >
                </a-input-group>
              </a-form-item>
              <a-form-item class="button-wrapper">
                <a-button
                  :loading="isSubmitLoading"
                  class="button"
                  type="primary"
                  html-type="submit"
                  >完成
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script setup lang="ts">
defineOptions({ name: 'Bind-mobile-modal-index' })

import { bindPhoneNumber, sendMessage } from '@/services/auth'
import { useUserStore } from '@/store/user'
import { SCENE_ID_MAP } from '@/types/enum/code'
import { Modal, message } from 'ant-design-vue'
import { reactive, ref, type UnwrapRef } from 'vue'
import { useRouter } from 'vue-router'

const props: any = defineProps({})

interface FormState {
  phone: string
  code: string
}
const formState: UnwrapRef<FormState> = reactive({
  mobile: '',
  phone: '',
  password: '',
  code: '',
  remember: false
})
const bindMobileModal = ref({
  isOpen: false
})
const validateCodeInterval = ref({
  time: 60,
  timer: 0,
  isWait: false,
  isLoading: false
})
const formRef = ref()
const isSubmitLoading = ref(false)
const store = useUserStore()
const router = useRouter()
const encryptUserId = ref('')
const bindMobileCode = ref('0')
const sendApllicaionMessage = async () => {
  if (validateCodeInterval.value.isWait) {
    return
  }
  validateCodeInterval.value.isLoading = true
  try {
    const values = await formRef.value.validateFields(['phone'])
    const res = await sendMessage({
      params: {
        phone: values.phone,
        sceneId: SCENE_ID_MAP.BIND
      }
    })
    message.info(res.message)
    if (res.code === '1') {
      encryptUserId.value = res.data
    } else {
      encryptUserId.value = ''
    }
    bindMobileCode.value = res.code
    validateCodeInterval.value.isWait = true
    validateCodeInterval.value.timer = setInterval(() => {
      if (validateCodeInterval.value.time === 0) {
        validateCodeInterval.value.time = 60
        validateCodeInterval.value.isWait = false
        clearInterval(validateCodeInterval.value.timer)
      } else {
        validateCodeInterval.value.time--
      }
    }, 1000)
    validateCodeInterval.value.isLoading = false
  } catch (e) {
    validateCodeInterval.value.isLoading = false
  }
}
const handleFinish = async (values: FormState) => {
  async function handle(encryptUserId?: string) {
    isSubmitLoading.value = true
    const params: any = { code: values.code, phoneNumber: values.phone }
    try {
      await bindPhoneNumber({ params })
      message.success('绑定成功！')
      isSubmitLoading.value = false
      bindMobileModal.value.isOpen = false
      store.handleUserInfo()
    } catch (e) {
      isSubmitLoading.value = false
    }
  }
  if (encryptUserId.value) {
    Modal.confirm({
      title: '绑定失败',
      content: `您的手机号${formState.phone}已被注册，是否绑定至当前登录账号`,
      async onOk() {
        handle(encryptUserId.value)
      }
    })
  } else {
    handle()
  }
}
const handleVisible = (val: boolean) => {
  bindMobileModal.value.isOpen = val
}
defineExpose({
  handleVisible
})
</script>

<style lang="less" scoped>
.component-bindMobileModal-container {
  .modal-inner-wrapper {
    // height: 300px;
    display: flex;
    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 240px;
      border-right: 1px solid #ececec;
      height: 100%;
      .icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 1px solid #ececec;
        .iconfont {
          font-size: 32px;
          transform: rotate(270deg);
        }
      }
    }
    .icon-shoujidenglu {
      font-size: 28px;
      color: #999;
      margin-left: -4px;
    }
    .icon-yanzhengma- {
      font-size: 24px;
      color: #999;
      position: relative;
      left: -2px;
    }
    .h-40 {
      height: 40px;
      box-shadow: none !important;
    }
    .operator-box {
      flex: 1;
      padding-top: 20px;
      .box-title {
        display: flex;
        align-items: center;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
        font-size: 22px;
        justify-content: center;
        margin-right: 10px;
        .icon-dianhua {
          font-size: 24px;
          margin-right: 18px;
        }
      }
      .title-desc {
        display: flex;
        justify-content: center;
        padding: 20px 0 10px 0;
        font-size: 12px;
        .brand {
          font-size: 12px;
          color: @primary-color;
          display: inline-block;
        }
        .highlight {
          font-size: 12px;
          color: rgb(255, 0, 0);
          display: inline-block;
        }
      }

      .form-wrapper {
        padding: 0 40px 20px 40px;
      }
      .validate-code-box {
        .ant-input-group.ant-input-group-compact {
          display: flex;
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        .button {
          width: 150px;
        }
      }
    }
  }
}
</style>
