// 引入Vue Router相关函数
import { createRouter, createWebHistory } from 'vue-router'
// 引入路由缓存钩子
import useRouteCache from '../hooks/useRouteCache'
// 引入路由缓存排除规则
import { excludeRule } from '../router/keepAliveRule'
// 引入重定向路径存储
// 导入用户状态管理store
import { useUserStore } from '@/store/user'
// 引入控制弹窗显示状态
// 导入输入header输入框状态管理store
import { useinputHighlightStore } from '@/store/inputHighlight'
// 导入获取token的函数
import { getToken } from '@/utils/common'
import { reportPoint } from '@/services/system'
import { nextTick } from 'vue'

// 添加缓存的函数
const { addCache } = useRouteCache()

// 动态导入所有视图组件
const modules = import.meta.glob('../views/**/*.vue')

// 用于存储组件名称的数组
const componentNameArr = []

// 用于存储路由信息的数组
const routeList = []
// 遍历modules对象，获取每个视图组件的路由路径和组件名称
for (const key in modules) {
  let routePath = key.replace('../views', '').replace('.vue', '')
  // 清除第一个 / 并将第二个 / 替换成 -
  const componentName = routePath.replace('/', '').replace('/', '-')
  // 确保组件名称有效且不在排除规则列表中，然后将其添加到组件名称数组
  if (componentName && !excludeRule.includes(componentName)) {
    componentNameArr.push(componentName)
  }
  // 对于/home/index路径，简化为根路径'/'
  routePath = routePath === '/home/index' ? '/' : routePath

  // 创建路由对象并添加到路由列表中
  const obj = {
    path: routePath,
    component: modules[key]
  }
  routeList.push(obj)
}

// 将有效组件名称数组添加到缓存
addCache(componentNameArr as string[])

// 创建路由实例，配置路由历史模式和路由列表
const router = createRouter({
  history: createWebHistory(),
  routes: routeList
})

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  //跳转期刊对比 清除header输入框输入的值
  if (to.path === '/selectivePeriodical/selectivePeriodical') {
    const inputStore = useinputHighlightStore()
    inputStore.inputValue = ''
  }

  // 使用用户状态管理 store，用于管理用户相关的状态
  const userStore = useUserStore()
  //  用户第一次登录且未绑定邮箱弹出绑定邮箱
  // if (userStore.isLogin && !userStore.userData.user.email && !localStorage.getItem('hiddenBind')) {
  //   const emailStore = usebindEmailStore()
  //   emailStore.changeModal()
  // }
  // 判断是否是第一次进入网站
  // const isFirstEnter =
  //   from.path === '/' &&
  //   Object.keys(from.params).length === 0 &&
  //   Object.keys(from.query).length === 0 &&
  //   from.matched.length === 0 &&
  //   from.name === undefined

  // 没有xuezhice参数，则限制登录
  // 当前时间大于2025-01-20 00:00:00 限制登录
  if (!to.query.xuezhice || Date.now() > 1737302400000) {
    // 需要登录才能访问的路径
    const needLoginPaths = ['/search/practical-info', '/search/country-nature-info']
    // 这些路径跳转登录后返回首页
    // const specialPaths = ['/user/forgetPassword', '/user/personal-edit', '/user/register']

    // 未登录无法访问
    if (!getToken() && needLoginPaths.includes(to.path)) {
      next({
        path: '/user/login',
        query: { redirect: to.fullPath }
      })
      return
    }
    // 已登录无法进入登录页
    if (to.path === '/user/login' && getToken()) {
      next({
        path: '/'
      })
      return
    }
  }
  // 跳转登录记录跳转前的路径
  if (to.path === '/user/login' && from.path !== '/user/login' && !to.query.redirect) {
    next({
      path: '/user/login',
      query: { redirect: from.fullPath }
    })
    return
  }
  next()
  nextTick(() => {
    reportPoint({
      path: to.path,
      traceId: new Date().getTime(),
      element: '跳转',
      elementValue: '跳转 [click]',
      relationValue: '跳转页面'
    })
  })
})

router.afterEach((to, from) => {
  // 每次跳转时，自动滚动到页面顶部
  window.scrollTo(0, 0)
})
// 导出默认的路由实例
export default router
